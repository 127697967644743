import React from 'react'
import {
    Flex,
    Image,
    Heading,
    forwardRef,
    Checkbox as Check,
    useTheme,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'

const MotionFlex = motion(Flex)

const Checkbox = ({ label, img, isChecked, setIsChecked }) => {
    const theme = useTheme()

    return (
        <MotionFlex
            bg="white"
            direction={{ base: 'row', md: 'column' }}
            justify={{ base: 'space-between', md: 'space-between' }}
            align="center"
            py={{ base: 8, md: 8 }}
            my={{ base: '12px', md: '0px' }}
            px={{ base: 4, md: 12 }}
            borderRadius={'5%'}
            cursor="pointer"
            onClick={() => setIsChecked(!isChecked)}
            boxShadow={isChecked ? 'lg' : 'base'}
            filter={!isChecked && 'grayscale(100%)'}
            w={['100%', '100%', '156px', '170px', '200px']}
            h={['36px', '36px', '156px', '170px', '200px']}
            whileHover={{ y: -5, boxShadow: theme.shadows.lg }}
            whileTap={{ scale: 0.95 }}
        >
            <Flex
                w={{ md: '100%' }}
                h={{ md: '100%' }}
                direction={{ md: 'column' }}
                align="center"
                justify={{ base: 'space-between', md: 'center' }}
            >
                <Image
                    w={{ base: 10, md: '70%' }}
                    mt={{ base: 0, md: 6 }}
                    mr={{ base: 4, md: 0 }}
                    userSelect="none"
                    src={img}
                ></Image>
                <Heading
                    pt={{ md: 8 }}
                    size="xs"
                    userSelect="none"
                    align="center"
                >
                    {label}
                </Heading>
            </Flex>
            <Check
                borderRadius="50%"
                colorScheme="brand"
                display={{ base: 'flex', md: 'none' }}
                borderColor="#eeeeee"
                size="lg"
                isChecked={isChecked}
            />
        </MotionFlex>
    )
}

export default Checkbox
