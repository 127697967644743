import React from 'react'
import { Box, Text, Image } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import ContactForm from '~components/contact/contact-form'
import drawing from '~images/backgrounds/drawing.svg'

export default function ContactPage() {
    return (
        <>
            <SEO
                title={'Contact'}
                description="Get in touch with us to get an estimate for your business."
            />
            <Box>
                <Image
                    position="absolute"
                    w="100vw"
                    h="100%"
                    bottom="0"
                    objectPosition="0% 20%"
                    objectFit="cover"
                    src={drawing}
                    zIndex={-10}
                ></Image>
                <ContactForm />
                <Text textAlign="right" pr={1} color="lightgrey">
                    <div>
                        Icons made by{' '}
                        <a
                            href="https://www.flaticon.com/authors/freepik"
                            title="Freepik"
                        >
                            Freepik
                        </a>{' '}
                        from{' '}
                        <a href="https://www.flaticon.com/" title="Flaticon">
                            www.flaticon.com
                        </a>
                    </div>
                </Text>
            </Box>
        </>
    )
}
