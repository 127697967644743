import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'

import { FadeUpBox } from '~components/shared/custom-animation'

const CheckboxContainer = ({ title, children }) => {
    return (
        <Box w="100%" my="36px">
            <Heading pb={6} fontSize="md">
                {title}
            </Heading>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                gap={6}
            >
                {children}
            </Flex>
        </Box>
    )
}

export default CheckboxContainer
