import React, { useState, useEffect } from 'react'
import { Button, Text, Heading, Flex } from '@chakra-ui/react'
import emailjs from 'emailjs-com'

import ResponsiveBlock from '~components/shared/responsive-block'
import CheckboxContainer from '~components/contact/checkbox-container'
import Checkbox from '~components/contact/checkbox'
import InfoForm from '~components/contact/info-form'

import development from '~images/icons/Code.svg'
import design from '~images/icons/Paintbrush.svg'
import mobile from '~images/icons/Smartphone.svg'
import desktop from '~images/icons/Desktop.svg'
import otherDev from '~images/icons/Gears.svg'
import otherPlat from '~images/icons/Device.svg'

const ContactForm = () => {
    const [info, setInfo] = useState({
        name: '',
        email: '',
        message: '',
    })
    const [error, setError] = useState({
        name: false,
        email: false,
        message: false,
    })

    const [isDesign, setIsDesign] = useState(false)
    const [isDevelopment, setIsDevelopment] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)
    const [isOtherDevice, setIsOtherDevice] = useState(false)

    useEffect(() => {
        setError(prevState => ({
            ...prevState,
            name: false,
        }))
    }, [info.name])

    useEffect(() => {
        setError(prevState => ({
            ...prevState,
            email: false,
        }))
    }, [info.email])

    useEffect(() => {
        setError(prevState => ({
            ...prevState,
            message: false,
        }))
    }, [info.message])

    function resetForm() {
        setInfo({
            name: '',
            email: '',
            message: '',
        })
    }

    function sendEmail(event) {
        event.preventDefault()

        // Makes sure name isn't empty.
        if (!info.name) {
            setError(prevState => ({
                ...prevState,
                name: true,
            }))
        }
        //Checks the necessary things for an email.
        else if (
            !info.email.includes('@') ||
            !info.email.includes('.') ||
            info.email.includes(' ')
        ) {
            setError(prevState => ({
                ...prevState,
                email: true,
            }))
        }
        // Makes sure message isn't empty.
        else if (!info.message) {
            setError(prevState => ({
                ...prevState,
                message: true,
            }))
        } else {
            let templateParams = {
                name: info.name,
                email: info.email,
                message: info.message,
                design: isDesign,
                development: isDevelopment,
                other: isOther,
                mobile: isMobile,
                desktop: isDesktop,
                otherDevice: isOtherDevice,
            }

            emailjs.send(
                'gmail',
                'template_RbykZtVm',
                templateParams,
                'user_yGcGlELCXqRKLf0x0dQ5V'
            )
            resetForm()
        }
    }

    return (
        <ResponsiveBlock>
            <Flex justify="center">
                <Flex
                    w={['100%', '100%', '100%', '90%', '80%']}
                    pt="156px"
                    pb="72px"
                    align="center"
                    direction="column"
                >
                    <Heading fontSize={{ base: 'lg', md: '2xl' }} mb="24px">
                        Reach out to us
                    </Heading>
                    <CheckboxContainer title="What do you need?">
                        <Checkbox
                            label="Design"
                            img={design}
                            isChecked={isDesign}
                            setIsChecked={setIsDesign}
                        />
                        <Checkbox
                            label="Development"
                            img={development}
                            isChecked={isDevelopment}
                            setIsChecked={setIsDevelopment}
                        />
                        <Checkbox
                            label="Other"
                            img={otherDev}
                            isChecked={isOther}
                            setIsChecked={setIsOther}
                        />
                    </CheckboxContainer>
                    <CheckboxContainer title="Which platform is it for?">
                        <Checkbox
                            label="Mobile"
                            img={mobile}
                            isChecked={isMobile}
                            setIsChecked={setIsMobile}
                        />
                        <Checkbox
                            label="Desktop"
                            img={desktop}
                            isChecked={isDesktop}
                            setIsChecked={setIsDesktop}
                        />
                        <Checkbox
                            label="Other"
                            img={otherPlat}
                            isChecked={isOtherDevice}
                            setIsChecked={setIsOtherDevice}
                        />
                    </CheckboxContainer>
                    <InfoForm info={info} setInfo={setInfo} error={error} />
                    <Flex w="100%" justify={{ base: 'center', md: 'flex-end' }}>
                        <Button variant="brand" onClick={e => sendEmail(e)}>
                            Submit
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </ResponsiveBlock>
    )
}

export default ContactForm
