import React from 'react'
import {
    Box,
    FormControl,
    Heading,
    FormHelperText,
    Input,
    Textarea,
} from '@chakra-ui/react'

const InfoForm = ({ info, setInfo, error }) => {
    return (
        <Box w="100%" mt="48px">
            <Box
                display="flex"
                justifyContent={{ base: 'center', md: 'flex-end' }}
            >
                <FormControl action="/">
                    <Heading pb={3} fontSize="md">
                        What do you go by?
                    </Heading>
                    <Input
                        spellCheck="false"
                        borderColor={error.name ? '#f74734' : 'black'}
                        placeholder="Full Name"
                        value={info.name}
                        onChange={e => {
                            e.persist()
                            setInfo(prevState => ({
                                ...prevState,
                                name: e.target.value,
                            }))
                        }}
                    />
                    <FormHelperText
                        opacity={error.name ? '1' : '0'}
                        transition="1s"
                        color="#f74734"
                        mb={6}
                    >
                        Please enter a name.
                    </FormHelperText>
                    <Heading pb={3} fontSize="md">
                        What's a good email?
                    </Heading>
                    <Input
                        spellCheck="false"
                        borderColor={error.email ? '#f74734' : 'black'}
                        placeholder="Email Address"
                        value={info.email}
                        onChange={e => {
                            e.persist()
                            setInfo(prevState => ({
                                ...prevState,
                                email: e.target.value,
                            }))
                        }}
                    />
                    <FormHelperText
                        opacity={error.email ? '1' : '0'}
                        transition="1s"
                        color="#f74734"
                        mb={6}
                    >
                        Please enter a valid email.
                    </FormHelperText>
                    <Heading pb={3} fontSize="md">
                        Tell us what's up.
                    </Heading>
                    <Textarea
                        spellCheck="false"
                        borderColor={error.message ? '#f74734' : 'black'}
                        resize="none"
                        h="128px"
                        placeholder="Project Description"
                        value={info.message}
                        onChange={e => {
                            e.persist()
                            setInfo(prevState => ({
                                ...prevState,
                                message: e.target.value,
                            }))
                        }}
                    />
                    <FormHelperText
                        opacity={error.message ? '1' : '0'}
                        transition="1s"
                        color="#f74734"
                        mt={{ base: 0, md: -0.5 }}
                        mb={6}
                    >
                        Please enter a message.
                    </FormHelperText>
                </FormControl>
            </Box>
        </Box>
    )
}

export default InfoForm
